import React, { useState, Fragment, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import Header from '../components/Header'
import { reset_password, is_signin } from '../auth/index'
import {
  is_email_valid,
  password_validation
} from '../helper/form-validation'

const ResetPassword = (req) => {

  let params = new URLSearchParams(req.location.search)
  let parmEmail = params.get('email')
  let parmToken = params.get('token')

  const [error_message, set_error_message] = useState('')
  const [to_redirect, set_to_redirect] = useState(false)

  useEffect(() => {
    if (!parmEmail || !parmToken) {
      set_to_redirect(true)
    }
  }, [])

  const [form_data, set_form_data] = useState({
    email: parmEmail,
    token: parmToken,
    password: '',
    confirm_password: '',
  })

  const {
    email,
    password,
    confirm_password,
    token
  } = form_data

  const handle_form_change = name => event => {
    set_form_data({
      ...form_data,
      [name]: event.target.value
    })
  }

  const is_long_enough = () => {
    return form_data.password.length >= 8
  }

  const has_lowercase_letter = () => {
    return /[a-z]/.test(form_data.password)
  }

  const has_uppoercase_letter = () => {
    return /[A-Z]/.test(form_data.password)
  }

  const has_number = () => {
    return /[/[0-9]/.test(form_data.password)
  }

  const has_special_character = () => {
    return /[\!\@\#\$\%\^\&\*]/.test(form_data.password)
  }

  const submit_form = event => {
    event.preventDefault()

    const err_message = password_validation(password)

    if (!is_email_valid(form_data.email)) {
      set_error_message('Email is not valid.')
    } else if (!password || !confirm_password) {
      set_error_message('Passowrd fields are required.')
    } else if (password !== confirm_password) {
      set_error_message('Password fields must match')
    } else if (err_message) {
      set_error_message(err_message)
    } else {
      reset_password(form_data)
        .then(user_extid => {
          set_to_redirect(true)
        })
        .catch(err => {
          console.log('err', err);
          set_form_data({
            ...form_data,
            password: ''
          })
          set_error_message(err)
        })
    }
  }

  return (
    <Fragment>
      { is_signin() && <Redirect to="/dashboard" /> }
      { to_redirect && <Redirect to="/dashboard" /> }
      <Header create_acount_btn />
      <div className="center">
        <div className="modal">
          <h1 className="heading">
            Reset Password
          </h1>
          <div className="form w-form">
            <form
              className="access-form"
            >
              <div className="contact-form-grid">
                <p>Please enter a new password. Your new password must meet the following criteria.</p>
                <input
                  type="hidden"
                  className="text-field full w-input"
                  maxLength="256"
                  name="Email"
                  placeholder="Email"
                  onChange={handle_form_change('email')}
                  value={email}
                />
                <input
                  type="hidden"
                  className="text-field full w-input"
                  name="Token"
                  placeholder="Token"
                  value={token}
                />
                <ul className="password-requirement">
                  <li className={ is_long_enough() ? "password-requirement--satisfied" : "" }>
                    has 8 characters or more.
                  </li>
                  <li className={ has_lowercase_letter() ? "password-requirement--satisfied" : "" }>
                    contains 1 lowercase letter.
                  </li>
                  <li className={ has_uppoercase_letter() ? "password-requirement--satisfied" : "" }>
                    contains 1 uppercase letter.
                  </li>
                  <li className={ has_number() ? "password-requirement--satisfied" : "" }>
                    contains 1 number.
                  </li>
                  <li className={ has_special_character() ? "password-requirement--satisfied" : "" }>
                    contains 1 special character.
                  </li>
                </ul>
                <input
                  type="password"
                  className="text-field full w-input"
                  maxLength="256"
                  name="password"
                  placeholder="New password"
                  onChange={handle_form_change('password')}
                  value={password}
                />
                <input
                  type="password"
                  className="text-field full w-input"
                  maxLength="256"
                  name="confirm_password"
                  placeholder="Confirm new password"
                  onChange={handle_form_change('confirm_password')}
                  value={confirm_password}
                />
              </div>
              <input
                type="submit"
                value="Reset Password"
                className="button large w-button"
                onClick={submit_form}
              />
              { error_message && <small className="error-message">{ error_message }</small> }
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ResetPassword
