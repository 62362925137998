import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useHistory, useLocation } from 'react-router-dom';

import logoBad from '../../../assets/images/bad.svg';
import logoBlah from '../../../assets/images/blash.svg';
import logoContent from '../../../assets/images/content.svg';
import logoHappy from '../../../assets/images/happy.svg';
import logoSad from '../../../assets/images/sad.svg';
import { get_token_and_extid } from '../../../auth';
import Loader from '../../Loader';
import {
  Button,
  ButtonContainer,
  CloseButton,
  Container,
  ContainerAsk,
  ContainerChat,
  ContainerHeader,
  ContainerMessage,
  QuestionContainer,
  SpinnerContainer,
  ContainerOverflow,
  ContainerHeaderStorage,
  ContainerTextEmojis
} from './styled';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Typewriter from 'typewriter-effect';

const TEXT_SHOULD_WRITE_IN = 5000;

export const Chat = ({ onClose }) => {

  const emotions = useMemo(() => [
    {
      id: 1,
      image: `${logoBad}`,
      name: 'anger',
      question:
        "What specifically triggered your anger today? How does this anger manifest in your body? Describe the physical sensations. Write a letter to the person or situation that made you angry (you don't have to send it). What positive actions can you take to channel this anger productively?",
    },
    {
      id: 2,
      image: `${logoBlah}`,
      name: 'sadness',
      question:
        "What thoughts or memories are contributing to your sadness? How can you practice self-compassion during this time? Write about a time when you overcame sadness. What helped you? What comforting words would you offer a friend feeling the same way?",
    },
    {
      id: 3,
      image: `${logoSad}`,
      name: 'indifferent',
      question:
        "What activities usually spark joy or interest for you? Write about something you're looking forward to, no matter how small. List five things you're grateful for today. Describe a place where you feel most content and why.",
    },
    {
      id: 4,
      image: `${logoContent}`,
      name: 'content',
      question:
        "What elements of today contributed to your feeling of contentment? How can you continue cultivating this feeling in the future? Write about a person who played a role in your contentment today. Describe your ideal day. How does it align with how you feel now?",
    },
    {
      id: 5,
      image: `${logoHappy}`,
      name: 'happy',
      question:
        "What made you feel happy today? Can you pinpoint specific moments? How can you share this happiness with others? Write a thank-you note to someone who contributed to your happiness. Reflect on past struggles and how they shaped your current happiness.",
    },
  ], [])


  const { user_extid, token } = get_token_and_extid()
  const location = useLocation();
  const history = useHistory();

  const containerRef = useRef(null);
  const pollingInterval = 4000;

  const emotionParam = useMemo(() => new URLSearchParams(location.search).get('emotion'), [location.search]);
  const [selectedEmotion, setSelectedEmotion] = useState(null);
  const [isModalClosing, setIsModalClosing] = useState(false);
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const [error, setError] = useState(null);
  const [userMessage, setUserMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [historyChat, setHistoryChat] = useState([]);
  const [historyChatLocal, setHistoryChatLocal] = useState({ data: [], status: null });
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);
  const [showHeader, setShowHeader] = useState(true);

  const emotionMap = useMemo(() => {
    const map = {};
    emotions.forEach((emotion) => {
      map[emotion.name] = emotion;
    });
    return map;
  }, [emotions]);

  useEffect(() => {
    const matchedEmotion = emotionMap[emotionParam];

    if (matchedEmotion) {
      setSelectedEmotion(matchedEmotion);
    }
  }, [emotionMap, emotionParam]);

  useEffect(() => {
    if (isModalClosing) {
      const newSearch = new URLSearchParams(location.search);
      newSearch.delete('emotion');
      history.replace(`${location.pathname}?${newSearch.toString()}`);
      setIsModalClosing(false);
      onClose();
    }
  }, [location.search, history, location.pathname, isModalClosing, onClose]);

  const handleCloseModal = () => {
    setIsModalClosing(true);
  };

  const handleEmotionClick = (emotion) => {
    setSelectedEmotion(emotion);
    setUserMessage(emotion.name);

    handleUserMessage(emotion);
  };

  const sendMessage = useCallback(
    async (message) => {
      try {
        setLoading(true);

        const userMessage = {
          id: Date.now(),
          role: 'user',
          content: [{ text: { value: message } }],
        };

        setShowHeader(false);
        setUserMessage('');

        const userLocalStorageKey = `historyData_${user_extid}`;
        let userHistoryData = JSON.parse(localStorage.getItem(userLocalStorageKey));

        // Garante que userHistoryData seja inicializado como um array se for null ou não for um array
        if (!Array.isArray(userHistoryData)) {
          userHistoryData = [];
        }

        userHistoryData.push(userMessage);
        localStorage.setItem(userLocalStorageKey, JSON.stringify(userHistoryData));

        setHistoryChat((prevHistory) => {
          const data = prevHistory?.data || [];
          const filteredData = data.filter(msg => msg.role !== 'user');

          return {
            ...prevHistory,
            data: [
              userMessage,
              ...filteredData,
            ],
            status: 'in_progress',
          };
        });

        setHistoryChatLocal((prevHistory) => ({
          ...prevHistory,
          data: [
            userMessage,
            ...(prevHistory.data || []),
          ],
          status: 'in_progress',
        }));

        const url = `${serverURL}/api/openai/chat/${user_extid}`;

        const responseFromServer = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ message: message.toString() }),
        });

        if (!responseFromServer.ok) {
          throw new Error(`Erro ${responseFromServer.status}: ${responseFromServer.statusText}`);
        }

        const newSearch = new URLSearchParams(location.search);
        newSearch.delete('emotion');
        history.replace(`${location.pathname}?${newSearch.toString()}`);

        setError(null);
        setUserMessage('');
      } catch (error) {
        console.log(error)
        setError(error.message || 'Ocorreu um erro na solicitação');
        toast.error('Erro na solicitação', {
          autoClose: 2000,
        });
      } finally {
        setLoading(false);
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }
    },
    [history, location.pathname, location.search, serverURL, token, user_extid]
  );


  const fetchData = useCallback(
    async () => {
      try {
        const response = await fetch(`${serverURL}/api/openai/status/${user_extid}`, {
          // const response = await fetch(`${serverURL}/api/openai/history/${user_extid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Erro ao obter dados');
        }

        const result = await response.json();

        //        localStorage.setItem('historyData', JSON.stringify(result));

        setHistoryChat((prevHistory) => ({
          ...prevHistory,
          data: result.data || prevHistory.data,
          status: result.status,
        }));

        // setHistoryChatLocal((prevHistory) => ({
        //   ...prevHistory,
        //   data: result.data || prevHistory.data,
        //   status: result.status,
        // }));

      } catch (error) {
        console.error('Erro durante o polling:', error);
      } finally {
        setLoading(false);
        if (containerRef.current) {
          setTimeout(() => {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
          }, 100);

        }
      }
    },
    [serverURL, token, user_extid]
  );


  const [lastId, setLastId] = useState('');
  const [hasMore, setHasMore] = useState(true)


  const fetchDataHistory = useCallback(async () => {
    try {
      const response = await fetch(`${serverURL}/api/openai/history/${user_extid} `, {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      });
      const result = await response.json();


      setHasMore(result.has_more || false)
      setLastId(result.last_id || '')


      localStorage.setItem(`historyData_${user_extid}`, JSON.stringify(result));

      setHistoryChatLocal((prevHistory) => ({
        ...prevHistory,
        data: result.data || prevHistory.data,
        status: result.has_more || prevHistory.has_more,
        last_id: result.last_id || prevHistory.last_id
      }))

    } catch (error) {
      console.error('Erro durante o pollling!', error)
    }
  }, [serverURL, token, user_extid])



  useEffect(() => {
    if (historyChat.status === 'completed') {
      return;
    }

    const historyPollingId = setInterval(fetchDataHistory, pollingInterval);
    const dataPollingId = setInterval(fetchData, pollingInterval);

    return () => {
      clearInterval(historyPollingId);
      clearInterval(dataPollingId);
    };
  }, [user_extid, token, pollingInterval, fetchData, historyChat.status, fetchDataHistory]);


  useEffect(() => {
    const matchedEmotion = emotionMap[emotionParam];

    if (matchedEmotion) {
      setSelectedEmotion(matchedEmotion);

      const initialMessage = `Right now I'm feeling: ${matchedEmotion.name}`;

      setUserMessage(initialMessage);
      sendMessage(initialMessage.toString());
    }
  }, [emotionMap, emotionParam, sendMessage]);


  useEffect(() => {
    const savedResultData = localStorage.getItem(`historyData_${user_extid}`);

    if (savedResultData) {
      const parsedResult = JSON.parse(savedResultData);

      setHistoryChatLocal((prevHistory) => ({
        ...prevHistory,
        data: parsedResult.data || prevHistory.data,
        status: parsedResult.status,
      }));

      setShouldScrollToBottom(true);
    }
  }, [user_extid]);

  useEffect(() => {
    if (shouldScrollToBottom && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
      setShouldScrollToBottom(false);
    }
  }, [shouldScrollToBottom]);

  const handleUserMessage = (emotion) => {
    if (emotion && userMessage.includes(emotion.name)) {

      setSelectedEmotion(null);
    }

    const message = `Right now I'm feeling ${emotion ? emotion.name : ''}`;

    setUserMessage(message);
  };

  const storedMessages = useMemo(() => {
    const stored = localStorage.getItem(`historyData_${user_extid}`);

    if (!stored) return {};

    const parsedStored = JSON.parse(stored);

    return parsedStored;
  }, [user_extid]);

  //console.log({ storedMessages });

  const textToTypewrite = useMemo(() => {
    if (loading) {
      // console.log('There is no text sent from AI');
      return '';
    }

    if (!historyChat) {
      // console.log('No history stored on `historyChat` or on `historyChatLocal`');
      return '';
    }

    if (historyChat.status === 'in_progress') {
      //console.log('Chat is processing an answer...');
      return '';
    }

    const data = historyChat.data || [];
    const reversedData = data.reverse();
    const filteredData = reversedData.filter(item => item.role === 'assistant');

    const lastMessageSent = filteredData[0];

    if (!lastMessageSent) {
      //  console.log('Could not find last message sent from assistant', reversedData);
      return '';
    }

    const { data: storedData } = storedMessages;
    const lastMessageIsFromHistory = storedData?.some(
      msg => msg.id === lastMessageSent.id
    ) || false;

    if (lastMessageIsFromHistory) {
      // console.log(`Message ${lastMessageSent.id} is from storage!`);
      return '';
    }

    //console.log({ lastMessageSent });

    return lastMessageSent;
  }, [historyChat, loading, storedMessages]);

  const handleTypewriteEffect = useCallback((typewriteEffectProps, texts = []) => {
    if (!texts.length) {
      // console.log('No texts present on function!', texts);
      return;
    }

    let typewriteEffect = typewriteEffectProps;
    // let typewriteEffect = new TypewriterClass();

    typewriteEffect = texts.reduce((acc, cur) => {
      const elementString = `
        <div class="question-container">
          <h1 style="white-space: pre-line; ">${cur}</h1>
        </div>
      `;
      acc = typewriteEffect.typeString(elementString).pauseFor(100);

      return acc;
    }, typewriteEffect);

    typewriteEffect.start().callFunction(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    });
  }, []);

  const charAppearingTime = useMemo(() => {
    if (!textToTypewrite) {
      //      console.log('Cannot calc time without message!');
      return;
    }

    const texts = textToTypewrite.content.map(content => content.text.value);

    if (!texts.length) {
      // console.log('Texts does not have length!');
      return 0;
    }

    const joinedTextsLength = texts.join(' ').length;

    return joinedTextsLength / TEXT_SHOULD_WRITE_IN;
  }, [textToTypewrite]);


  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      console.log('Enter key pressed');
      sendMessage(userMessage);
    } else if (e.key === 'Enter' && e.shiftKey) {
      setUserMessage((prevMessage) => prevMessage);
    }
  };


  const [hasRequested, setHasRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataHistoricAll, setDataHistoricAll] = useState([])


  useEffect(() => {
    const handleScroll = async () => {
      const container = containerRef.current;

      if (container.scrollTop === 0 && !hasRequested && hasMore) {

        setIsLoading(true);

        try {
          const response = await fetch(`${serverURL}/api/openai/history/${user_extid}?last_id=${lastId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const result = await response.json();

            setDataHistoricAll((prevHistory) => ({
              ...prevHistory,
              data: Array.isArray(prevHistory.data) ? [...result.data, ...prevHistory.data] : result.data,
            }));

            setHasMore(result.has_more || false);
            setLastId(result.last_id || '');

            if (result.data.length > 0) {
              const lastItem = result.data[0];

              const lastItemElement = document.getElementById(`msg_${lastItem.id}`);


              if (lastItemElement) {
                lastItemElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
              }
            }
          }
        } catch (error) {
          setError(error.message || 'An error occurred in the request');
          toast.error('Request error', {
            autoClose: 2000,
          });
        } finally {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        }

        setHasRequested(true);
      } else if (container.scrollTop > 0 && hasRequested) {
        setHasRequested(false);
      }
    };

    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [hasMore, hasRequested, lastId, serverURL, token, user_extid]);


  // useEffect(() => {
  //   const userLocalStorageKey = `historyData_${user_extid}`;
  //   const userHistoryData = JSON.parse(localStorage.getItem(userLocalStorageKey)) || [];

  //   setHistoryChatLocal((prevHistory) => ({
  //     ...prevHistory,
  //     data: userHistoryData,
  //     status: 'completed',
  //   }));
  // }, [user_extid]);



  return (
    <>
      <ToastContainer position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {historyChat && (
        <>

          <Container>
            <div>
              <CloseButton onClick={handleCloseModal}>
                <AiOutlineClose size={24} />
              </CloseButton>
            </div>


            {/* Rendered when navigated from flag on dashboard "Talk to Twella AI" */}
            {location.search === '' && (
              <>
                <ContainerOverflow
                  ref={ref => {
                    if (ref) {
                      containerRef.current = ref;
                    }
                  }}
                >
                  <div style={{ overflowY: 'auto', maxHeight: 'auto' }}>
                    <>
                      {isLoading && (
                        <div style={{ padding: '10px', display: "flex", justifyContent: "center" }}>
                          <Loader />
                        </div>
                      )}

                      {dataHistoricAll.data && (
                        dataHistoricAll.data.slice().reverse().map((msg) => {
                          const shouldTypewrite = msg.id === textToTypewrite.id;

                          return (
                            <div key={msg.id} id={`msg_${msg.id}`}>
                              {msg.role === 'user' && (
                                <ContainerMessage>
                                  <h1 style={{ whiteSpace: 'pre-wrap' }}>{msg?.content[0]?.text.value}</h1>
                                </ContainerMessage>
                              )}
                              {msg.role === 'assistant' && (
                                shouldTypewrite ? (
                                  <Typewriter
                                    options={{
                                      cursor: '',
                                      delay: charAppearingTime || 25
                                    }}
                                    onInit={typewriter => {
                                      const textsToWrite = textToTypewrite
                                        .content
                                        .map(c => c.text.value);

                                      handleTypewriteEffect(typewriter, textsToWrite);
                                    }}
                                  />
                                ) : (
                                  <QuestionContainer >
                                    <h1 style={{ whiteSpace: 'pre-line' }}>{msg.content[0]?.text?.value}</h1>
                                  </QuestionContainer>
                                )
                              )}
                            </div>
                          );
                        })
                      )}
                    </>



                    {historyChatLocal.data && Array.isArray(historyChatLocal.data) && (
                      historyChatLocal.data.slice().reverse().map((msg) => {
                        const shouldTypewrite = msg.id === textToTypewrite.id;

                        return (
                          <div key={msg.id}>
                            {msg.role === 'user' && (
                              <ContainerMessage>
                                <h1 style={{ whiteSpace: 'pre-line' }}>{msg.content[0]?.text?.value}</h1>
                              </ContainerMessage>
                            )}
                            {msg.role === 'assistant' && (
                              shouldTypewrite ? (
                                <Typewriter
                                  options={{
                                    cursor: '',
                                    delay: charAppearingTime || 25
                                  }}
                                  onInit={typewriter => {
                                    const textsToWrite = textToTypewrite
                                      .content
                                      .map(c => c.text.value);

                                    handleTypewriteEffect(typewriter, textsToWrite);
                                  }}
                                />
                              ) : (
                                <QuestionContainer>
                                  <h1 style={{ whiteSpace: 'pre-line' }}>{msg.content[0]?.text?.value}</h1>
                                </QuestionContainer>
                              )
                            )}
                          </div>
                        );
                      })
                    )}


                    {(historyChat.status === 'in_progress' || historyChat.status === 'queued') && (
                      <div style={{ padding: "0px 24px 0px 24px" }}>
                        <SpinnerContainer>
                          <Loader />
                        </SpinnerContainer>
                      </div>
                    )}
                  </div>

                  {showHeader && (
                    <ContainerHeaderStorage>
                      <h1>To better help, please let us know how you are feeling right now?</h1>
                      <ButtonContainer>
                        <div></div>
                        {emotions.map((emotion) => (
                          <div key={emotion.id}>
                            <Button
                              key={emotion.id}
                              onClick={() => {
                                handleEmotionClick(emotion);

                                const message = `Right now I'm feeling ${emotion.name}`;

                                sendMessage(message)
                              }}
                              selected={selectedEmotion && selectedEmotion.id === emotion.id}
                            >
                              <img src={emotion.image} alt={emotion.question} />
                            </Button>
                          </div>
                        ))}
                      </ButtonContainer>

                      <ContainerTextEmojis>
                        {emotions.map((emotion) => (
                          <div key={emotion.id}>
                            <span>{emotion.name}</span>
                          </div>
                        ))}
                      </ContainerTextEmojis>
                    </ContainerHeaderStorage>
                  )}
                </ContainerOverflow>

                <ContainerAsk>
                  <textarea
                    rows={1}
                    type="text"
                    placeholder="Ask me anything..."
                    onChange={(e) => setUserMessage(e.target.value)}
                    value={userMessage}
                    onKeyDown={handleKeyDown}
                  />


                  <button
                    onClick={() => sendMessage(userMessage)}
                    disabled={!userMessage.trim()}
                    style={{ cursor: !userMessage.trim() ? 'not-allowed' : 'pointer', touchAction: 'manipulation' }}
                  >
                    <img src='images/message-send.svg' alt='Send' />
                  </button>

                </ContainerAsk>

              </>
            )}


            {location.search === `?emotion=${emotionParam}` && (
              <>
                <ContainerOverflow ref={containerRef}>
                  <ContainerHeader>
                    <h1>
                      To better help, please let us know how you are feeling right now?
                    </h1>

                    <ButtonContainer>
                      <div></div>
                      {emotions.map((emotion) => (
                        <div key={emotion.id}>
                          <Button
                            key={emotion.id}
                            onClick={() => handleEmotionClick(emotion)}
                            selected={selectedEmotion && selectedEmotion.id === emotion.id}
                          >
                            <img src={emotion.image} alt={emotion.question} />
                          </Button>
                        </div>
                      ))}
                    </ButtonContainer>
                  </ContainerHeader>

                  <ContainerChat
                    ref={ref => {
                      // if (!ref || !containerRef || !containerRef.current) return;

                      // const observer = new ResizeObserver(() => {
                      //   if (!containerRef.current) return;

                      //   containerRef.current.scrollTop = ref.scrollHeight;
                      // });

                      // for (let child of ref.children) {
                      //   observer.observe(child);
                      // }

                      if (ref) {
                        containerRef.current = ref;
                      }
                    }}
                  >
                    <div style={{ overflowY: 'auto', maxHeight: 'auto' }}>

                      <>
                        {isLoading && (
                          <div style={{ padding: '10px', display: "flex", justifyContent: "center" }}>
                            <Loader />
                          </div>
                        )}

                        {dataHistoricAll.data && (
                          dataHistoricAll.data.slice().map((msg) => {
                            const shouldTypewrite = msg.id === textToTypewrite.id;

                            return (
                              <div key={msg.id}>
                                {msg.role === 'user' && (
                                  <ContainerMessage>
                                    <h1 style={{ whiteSpace: 'pre-line' }}>{msg.content[0]?.text?.value}</h1>
                                  </ContainerMessage>
                                )}
                                {msg.role === 'assistant' && (
                                  shouldTypewrite ? (
                                    <Typewriter
                                      options={{
                                        cursor: '',
                                        delay: charAppearingTime || 25
                                      }}
                                      onInit={typewriter => {
                                        const textsToWrite = textToTypewrite
                                          .content
                                          .map(c => c.text.value);

                                        handleTypewriteEffect(typewriter, textsToWrite);
                                      }}
                                    />
                                  ) : (
                                    <QuestionContainer>
                                      <h1 style={{ whiteSpace: 'pre-line' }}>{msg.content[0]?.text?.value}</h1>
                                    </QuestionContainer>
                                  )
                                )}
                              </div>
                            );
                          })
                        )}
                      </>


                      {historyChatLocal.data && Array.isArray(historyChatLocal.data) && (
                        historyChatLocal.data.slice().reverse().map((msg) => {
                          const shouldTypewrite = msg.id === textToTypewrite.id;

                          return (
                            <div key={msg.id}>
                              {msg.role === 'user' && (
                                <ContainerMessage>
                                  <h1 style={{ whiteSpace: 'pre-line' }}>{msg.content[0]?.text?.value}</h1>
                                </ContainerMessage>
                              )}

                              {msg.role === 'assistant' && (
                                shouldTypewrite ? (
                                  <Typewriter
                                    options={{
                                      cursor: '',
                                      delay: charAppearingTime || 25
                                    }}
                                    onInit={typewriter => {
                                      const textsToWrite = textToTypewrite
                                        .content
                                        .map(c => c.text.value);

                                      handleTypewriteEffect(typewriter, textsToWrite);
                                    }}
                                  />
                                ) : (
                                  <QuestionContainer>
                                    <h1 style={{ whiteSpace: 'pre-line' }}>{msg.content[0]?.text?.value}</h1>
                                  </QuestionContainer>
                                )
                              )}
                            </div>
                          );
                        })
                      )}
                    </div>


                    {(historyChat.status === 'in_progress' || historyChat.status === 'queued') && (
                      <div>
                        <SpinnerContainer>
                          <Loader />
                        </SpinnerContainer>
                      </div>
                    )}

                  </ContainerChat>
                </ContainerOverflow>

                <ContainerAsk>
                  <textarea
                    rows={1}
                    type="text" placeholder="Ask me anything..."
                    onChange={(e) => setUserMessage(e.target.value)}
                    value={userMessage}
                  />

                  <button
                    onClick={() => sendMessage(userMessage)}
                    disabled={!userMessage.trim()}
                    style={{ cursor: !userMessage.trim() ? 'not-allowed' : 'pointer' }}
                  >
                    <img src='images/message-send.svg' alt='Send' />
                  </button>

                </ContainerAsk>
              </>
            )}
          </Container>

        </>
      )}
    </>
  );
};

