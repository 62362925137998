const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const PHONE_REGEX = /^[\d\s\-\(\)]{3,}$/
const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5})(:[0-9]{1,5})?(\/.*)?$/i

const is_email_valid = (email) => {
  return EMAIL_REGEX.test(email)
}

const is_age_valid = (age) => {
  return age >= 0 && age <= 150
}

const is_phone_valid = (phone) => {
  return PHONE_REGEX.test(phone)
}

const password_validation = (password) => {
  if (password.length < 8) {
    return 'Password must has 8 characters or more.'
  }

  if (!/[a-z]/.test(password)) {
    return 'Must contain 1 lowercase letter.'
  }

  if (!/[A-Z]/.test(password)) {
    return 'Must contain 1 uppercase letter.'
  }

  if (!/[/[0-9]/.test(password)) {
    return 'Must contain 1 number.'
  }

  if (!/[\!\@\#\$\%\^\&\*]/.test(password)) {
    return 'Must contain 1 special character.'
  }

  return ''
}

const url_validation = (url) => {
  const match = url.match(URL_REGEX)
  if (match) {
    return match[2]
  }
  return false
}

export {
  is_email_valid,
  is_age_valid,
  is_phone_valid,
  password_validation,
  url_validation
}
