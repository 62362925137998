import Cookies from 'universal-cookie'

const SERVER_URL = process.env.REACT_APP_SERVER_URL
if (!SERVER_URL) {
  throw new Error('missing REACT_APP_SERVER_URL')
}

const COOKIE_AGE_IN_SECONDS = 30 * 24 * 60 * 60 // 30 DAYS

const cookies = new Cookies()

const update_token_and_extid = (token, user_extid) => {
  cookies.set(
    'jwt',
    token,
    {
      path: '/',
      maxAge: COOKIE_AGE_IN_SECONDS
    }
  )
  cookies.set(
    'user_extid',
    user_extid,
    {
      path: '/',
      maxAge: COOKIE_AGE_IN_SECONDS
    }
  )
}

const get_token_and_extid = () => {
  const token = cookies.get('jwt')
  const user_extid = cookies.get('user_extid')
  return {
    token,
    user_extid
  }
}

const is_signin = () => {
  const {
    token,
    user_extid
  } = get_token_and_extid()

  if (token && user_extid) {
    return true
  }

  return false
}

const signup = (form_data) => {
  return fetch(`${SERVER_URL}/api/auth/signup`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(form_data)
  })
    .then(response => response.json())
    .then(data => {
      const {
        user_extid,
        token
      } = data
      
      if (user_extid && token) {
        update_token_and_extid(token, user_extid)
        return user_extid
      }

      throw new Error('Invalid credentials.')
    })
}

const signin = (form_data) => {
  return fetch(`${SERVER_URL}/api/auth/signin`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(form_data)
  })
    .then(response => response.json())
    .then(data => {
      const {
        user_extid,
        token
      } = data
      
      if (user_extid && token) {
        update_token_and_extid(token, user_extid)
        return user_extid
      }

      throw new Error('Invalid credentials.')
    })
}

const signout = () => {
  cookies.remove('jwt')
  cookies.remove('user_extid')
}

const update_password = (form_data) => {
  const {
    token,
    user_extid
  } = get_token_and_extid()

  if (!token || !user_extid) {
    return new Promise((resolve, reject) => {
      return resolve({
        status: 'error',
        error: 'missing token and/or user_extid'
      })
    })
  }

  return fetch(`${SERVER_URL}/api/auth/update-password/${JSON.parse(user_extid)}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(token)}`
    },
    body: JSON.stringify(form_data)
  })
    .then(response => response.json())
    .then(response => {
      const { error } = response

      if (error) {
        throw new Error(error)
      }
      return true
    })
}

const reset_password = (form_data) => {
  return fetch(`${SERVER_URL}/api/auth/reset-password`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(form_data)
  })
    .then(response => response.json())
    .then(response => {
      const { error } = response

      if (error) {
        throw new Error(error)
      }
      return true
    })
}

const forgot_password = (form_data) => {
  return fetch(`${SERVER_URL}/api/auth/forgot-password`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(form_data)
  })
    .then(response => response.json())
    .then(response => {
      const { error } = response

      console.log('response', response);

      if (error) {
        throw new Error(error)
      }
      return true
    })
}

export {
  get_token_and_extid,
  is_signin,
  signup,
  signin,
  signout,
  update_password,
  reset_password,
  forgot_password
}
