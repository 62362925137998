import React, { useState, useEffect } from 'react';
import { InputContainer, InputField, Label } from './styled';

const FormControl = ({ label, name, value, onChange, type, ...props }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState(value || '');

    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        if (!inputValue) {
            setIsFocused(false);
        }
    };


    return (
        <InputContainer>
            <Label isFocused={isFocused} hasContent={inputValue}>
                {label}
            </Label>
            <InputField
                {...props}
                type={type}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={inputValue}
                onChange={onChange}
            />
        </InputContainer>
    );
};


export default FormControl;
