import { Modal } from "../index";
import styled from "styled-components";

export const ModalModify = styled(Modal)`
  background: #12141d;
  border: 2px solid #5ca0b3;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 40px;
  border-radius: 20px;

  width: 430px;
  height: auto;
`;

export const ContainerFreeTrial = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 40px;

  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    gap: 24px;

    > h1 {
      font-size: 28px;
      line-height: 34px;
      letter-spacing: 26px;
      color: #fff;
      font-weight: 400;
      text-transform: uppercase;
      margin-left: 13px;

      opacity: 50%;
    }

    > hr { 
        border: 1px solid rgba(255, 255, 255, 1);
        width: 100%;
        opacity: 10%;

    }

    p {
      font-size: 50px;
      letter-spacing: -0.35px;
      line-height: 70px;
      font-weight: 900;
      color: #fff;

      

      > span {
        color: rgba(134, 231, 184, 1);
      }
    }
  }
  > div:nth-child(2) {
    display: flex;

    width: 100%;
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;


  color: #fff;

  &::before {
    content: "✔";
    display: flex;
    justify-content: center;
    text-align: center;

    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(134, 231, 184, 1);
    margin-right: 10px;

    color: #fff;
  
  }
`;

export const List = styled.ul`
  list-style: none;
  padding-left: 0;


  > p   { 
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: rgba(255, 255, 255, 1);
    opacity: 0.5;

  }
`;


export const ContainerButtons = styled.div`
    display: flex;
    justify-content: center;

    flex-direction: column;

    gap: 16px;
    width: 100%;
  

 > button  {
    height: 48px;
    border-radius: 5px;


    &:nth-child(1) {
        background-color: transparent;
        color: rgba(92, 160, 179, 1);

        border: 1px solid rgba(92, 160, 179, 1)

    }

    &:nth-child(2) { 
        background: rgba(92, 160, 179, 1);
        color: rgba(255, 255, 255, 1);


    }
 }

`