import React, { useState, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import {
  is_email_valid,
  password_validation
} from '../helper/form-validation'
import { signup } from '../auth/index'
import Header from '../components/Header'
import './Signup.scss'

const Signup = () => {
  const [form_data, set_form_data] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',
    error_message: ''
  })
  const [to_dashboard, set_to_dashboard] = useState(false)

  const handle_form_change = name => event => {
    set_form_data({
      ...form_data,
      [name]: event.target.value
    })
  }

  const is_long_enough = () => {
    return form_data.password.length >= 8
  }

  const has_lowercase_letter = () => {
    return /[a-z]/.test(form_data.password)
  }

  const has_uppoercase_letter = () => {
    return /[A-Z]/.test(form_data.password)
  }

  const has_number = () => {
    return /[/[0-9]/.test(form_data.password)
  }

  const has_special_character = () => {
    return /[\!\@\#\$\%\^\&\*]/.test(form_data.password)
  }

  const submit_form = event => {
    event.preventDefault()

    const {
      first_name,
      last_name,
      email,
      password,
      confirm_password
    } = form_data

    const error_message = password_validation(password)

    if (!first_name) {
      set_form_data({
        ...form_data,
        error_message: 'First name is required.'
      })
    } else if (!is_email_valid(form_data.email)) {
      set_form_data({
        ...form_data,
        error_message: 'Email is not valid.'
      })
    } else if (!password || !confirm_password) {
      set_form_data({
        ...form_data,
        error_message: 'Passowrd fields are required.'
      })
    } else if (password !== confirm_password) {
      set_form_data({
        ...form_data,
        password: '',
        confirm_password: '',
        error_message: 'Password fields must match'
      })
    } else if (error_message) {
      set_form_data({
        ...form_data,
        password: '',
        confirm_password: '',
        error_message
      })
    } else {
      signup({
        first_name,
        last_name,
        email,
        password
      })
        .then(() => {
          window.open(
            'https://chrome.google.com/webstore/detail/twella/lblidfjkplakdhnmbghmeomhbkakbcko',
            '_blank'
          )
          set_to_dashboard(true)
        })
        .catch(err => {
          console.log('Error signing up', err)
          set_form_data({
            ...form_data,
            password: '',
            confirm_password: '',
            error_message: 'Create account unsuccessful.'
          })
        })
    }

    setTimeout(() => {
      set_form_data({
        ...form_data,
        error_message: ''
      })
    }, 5000)
  }

  return (
    <Fragment>
      { to_dashboard && <Redirect to='/dashboard' /> }
      <Header signin_btn />
      <div className="center">
        <div className="modal">
          <h1 className="heading">
            Sign Up
          </h1>
          <div className="form w-form">
            <form
              className="access-form"
            >
              <div className="contact-form-grid">
                <input
                  type="text"
                  className="text-field full w-input"
                  maxLength="256"
                  name="First Name"
                  placeholder="First Name"
                  onChange={handle_form_change('first_name')}
                  value={form_data.first_name}
                />
                <input
                  type="text"
                  className="text-field full w-input"
                  maxLength="256"
                  name="Last Name"
                  placeholder="Last Name"
                  onChange={handle_form_change('last_name')}
                  value={form_data.last_name}
                />
                <input
                  type="email"
                  className="text-field full w-input"
                  maxLength="256"
                  name="Email"
                  placeholder="Email"
                  onChange={handle_form_change('email')}
                  value={form_data.email}
                />
                <input
                  type="password"
                  className="text-field full w-input"
                  maxLength="256"
                  name="Password"
                  placeholder="Password"
                  onChange={handle_form_change('password')}
                  value={form_data.password}
                />
                <ul className="password-requirement">
                  <li className={ is_long_enough() ? "password-requirement--satisfied" : "" }>
                    has 8 characters or more.
                  </li>
                  <li className={ has_lowercase_letter() ? "password-requirement--satisfied" : "" }>
                    contains 1 lowercase letter.
                  </li>
                  <li className={ has_uppoercase_letter() ? "password-requirement--satisfied" : "" }>
                    contains 1 uppercase letter.
                  </li>
                  <li className={ has_number() ? "password-requirement--satisfied" : "" }>
                    contains 1 number.
                  </li>
                  <li className={ has_special_character() ? "password-requirement--satisfied" : "" }>
                    contains 1 special character.
                  </li>
                </ul>
                <input
                  type="password"
                  className="text-field full w-input"
                  maxLength="256"
                  name="Confirm Password"
                  placeholder="Confirm Password"
                  onChange={handle_form_change('confirm_password')}
                  value={form_data.confirm_password}
                />
              </div>
              <input
                type="submit"
                value="Sign Up"
                className="button large w-button"
                onClick={submit_form}
              />
              { form_data.error_message && (
                <small className="error-message">{ form_data.error_message }</small>
              ) }
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Signup
