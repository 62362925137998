import React from 'react'
import {
  Route,
  Redirect
} from 'react-router-dom'
import { is_signin } from './index'

const PrivateRoute = ({ component: Component, ...attrs }) => (
  <Route
    { ...attrs }
    render={ props => is_signin() ? (
      <Component { ...props } />
    ) : (
      <Redirect
        to={{
          pathname: '/signin',
          state: {
            from: props.location
          }
        }}
      />
    )}
  />
)

export default PrivateRoute
