import React, { useRef, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";

import { ModalOverlay, ModalContent, CloseButton } from "./styled";

export const Modal = ({ isOpen, onClose, width, height, children, ...props }) => {
  const modalOverlayRef = useRef(null);

  const disableBackgroundScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableBackgroundScroll = () => {
    document.body.style.overflow = 'auto';
  };

  const handleOverlayClick = (e) => {
    if (modalOverlayRef.current === e.target) {
      onClose();
    }
  };
  useEffect(() => {
    if (isOpen) {
      disableBackgroundScroll();
    } else {
      enableBackgroundScroll();
    }

    return () => {
      enableBackgroundScroll();
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalOverlay
      ref={modalOverlayRef}
      isOpen={isOpen}
      onClick={handleOverlayClick}
    >
      <ModalContent width={width} height={height} {...props}>

        {children}
      </ModalContent>
    </ModalOverlay >
  );
};
