import React, { useState, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { signout } from '../auth/index';
import twellaLogo from '../assets/images/twella.png';

const Header = ({
  signin_btn = false,
  signout_btn = false,
  create_acount_btn = false
}) => {
  const [to_signin, set_to_signin] = useState(false)

  const to_signout = (event) => {
    event.preventDefault()
    signout()
    set_to_signin(true)    
  }

  return (
    <Fragment>
      { to_signin && <Redirect to="/signin" /> }
      <div
        ata-collapse="medium"
        data-animation="default"
        data-duration="400"
        role="banner"
        className="navbar w-nav"
      >
        <div className="navbar">
          <a href="/" className="brand w-nav-brand">
            <img src={twellaLogo} alt="twella logo" className="image" />
          </a>
          {
            signin_btn && (
              <a href="/signin" className="button w-button">
                Sign in
              </a>
            )
          }
          {
            signout_btn && (
              <a onClick={to_signout} className="button w-button">
                Sign out
              </a>
            )
          }
          {
            create_acount_btn && (
              <a href="/create-account" className="button w-button">
                Create Account
              </a>
            )
          }
        </div>
      </div>
    </Fragment>
  )
}

export default Header
