import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { get_user_profile, update_user } from '../user-profile/index'
import { signout, update_password } from '../auth/index'
import {
  is_age_valid,
  is_phone_valid,
  password_validation,
  url_validation
} from '../helper/form-validation'
import DEFAULT_BLOCKED_LINKS from '../helper/default-blocked-links'
import './Dashboard.scss'
import { Modal } from '../components/Modal/index'
import { Chat } from '../components/Modal/Chat/index'
import FreeTrial from '../components/Modal/FreeTrial'
import Payment from '../components/Modal/Payment'

import Cookies from 'universal-cookie';
import webEmojis from '../assets/icons/web-emojis.svg';
import websPurple from '../assets/icons/webs-purple.svg';
import Mixpanel from 'mixpanel-browser';
import FormControl from '../components/FormControl'
import { get_token_and_extid } from '../auth';

import { ModalModifyPayment } from '../components/Modal/Payment/styled'

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Dashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const [to_signin, set_to_signin] = useState(false)
  const [user, set_user] = useState({})
  const [visit_history, set_visit_history] = useState([])
  const [profile_form_data, set_profile_form_data] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    age: 0,
    error_message: '',
    success_message: '',
    email: ''
  })
  const [password_data, set_password_data] = useState({
    password: '',
    confirm_password: '',
    error_message: '',
    success_message: ''
  })
  const [blocked_links, set_blocked_links] = useState([])
  const [website_form_data, set_website_form_data] = useState({
    url: '',
    error_message: ''
  })


  // INIT
  useEffect(() => {
    get_user_profile()
      .then(user_profile => {
        const {
          user: _user,
          visit_history: _visit_history
        } = user_profile

        set_user(_user)
        set_visit_history(_visit_history)
      })
      .catch(error => {
        signout()
        set_to_signin(true)
      })
  }, [])


  useEffect(() => {
    set_profile_form_data(prevFormData => ({
      ...prevFormData,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      age: user.age,
      email: user.email
    }));
    if (user.blocked_links) {
      set_blocked_links(user.blocked_links.map(link => link.toLowerCase()));
    }
  }, [user]);

  const update_blocked_links = (new_blocked_links) => {
    update_user({ blocked_links: new_blocked_links })
      .then(() => set_blocked_links(new_blocked_links))
      .catch(err => console.error('Error updating blocked links', err))
  }

  const handle_profile_form_change = name => event => {
    set_profile_form_data({
      ...profile_form_data,
      [name]: event.target.value
    })
  }

  const submit_profile_form = event => {
    event.preventDefault()

    const {
      first_name,
      last_name,
      phone,
      age,
      email
    } = profile_form_data

    if (!first_name) {
      set_profile_form_data({
        ...profile_form_data,
        error_message: 'First name cannot be empty.',
        success_message: ''
      })
    } else if (phone && !is_phone_valid(phone)) {
      set_profile_form_data({
        ...profile_form_data,
        error_message: 'Invalid phone number.',
        success_message: ''
      })
    } else if (age && !is_age_valid(age)) {
      set_profile_form_data({
        ...profile_form_data,
        error_message: 'Invalid age.',
        success_message: ''
      })
    } else if (
      first_name === user.first_name &&
      last_name === user.last_name &&
      phone === user.phone &&
      age === user.age &&
      email === user.email
    ) {
      set_profile_form_data({
        ...profile_form_data,
        error_message: 'No change detected.',
        success_message: ''
      })
    } else {
      update_user({
        first_name,
        last_name,
        phone,
        age,
        email
      })
        .then(user_profile => {
          const { user: _user } = user_profile


          Mixpanel.track('Profile Updated', {
            first_name,
            last_name,
            phone,
            age,
            email
          });

          set_profile_form_data({
            ...profile_form_data,
            error_message: '',
            success_message: 'Update successful!'
          })


        })
        .catch(err => {

          toast.error(`${err}`, {
            autoClose: 8000,
          });

          set_profile_form_data({
            ...user,
            error_message: 'Update unsuccessful.',
            success_message: ''
          })
        })
    }

    setTimeout(() => {
      set_profile_form_data({
        ...profile_form_data,
        error_message: '',
        success_message: ''
      })
    }, 5000)
  }

  const handle_password_form_change = name => event => {
    set_password_data({
      ...password_data,
      [name]: event.target.value
    })
  }

  const submit_password_change = event => {
    event.preventDefault()

    const {
      password,
      confirm_password
    } = password_data

    const error_message = password_validation(password)

    if (!password || !confirm_password) {
      set_password_data({
        ...password_data,
        error_message: 'Password fields are required.',
        success_message: ''
      })
    } else if (password !== confirm_password) {
      set_password_data({
        password: '',
        confirm_password: '',
        error_message: 'Passwords must match.',
        success_message: ''
      })
    } else if (error_message) {
      set_password_data({
        password: '',
        confirm_password: '',
        error_message,
        success_message: ''
      })

    } else {
      update_password({ password })
        .then(() => {
          set_password_data({
            password: '',
            confirm_password: '',
            error_message: '',
            success_message: 'Update successful!'
          })
        })
        .catch(err => {
          console.log('Update password error', err)
          set_password_data({
            password: '',
            confirm_password: '',
            error_message: 'Update unsuccessful.',
            success_message: ''
          })
        })


    }

    setTimeout(() => {
      set_password_data({
        ...password_data,
        error_message: '',
        success_message: ''
      })
    }, 5000)
  }

  const handle_website_form_change = event => {
    set_website_form_data({
      ...website_form_data,
      url: event.target.value
    })
  }

  const fire_blocklist_change_event = (blockedLink) => {
    const blocklistEvent = new CustomEvent(
      'blocklist',
      {
        detail: {
          type: 'change',
          oldBlocklist: blocked_links,
          blockedLink,
          newBlocklist: [...blocked_links, blockedLink],
        }
      }
    );

    document.dispatchEvent(blocklistEvent);
  }

  const fire_blocklist_remove_event = (removed_link) => {
    const blocklistEvent = new CustomEvent(
      'blocklist',
      {
        detail: {
          type: 'remove',
          removedLink: removed_link,
        }
      }
    );

    document.dispatchEvent(blocklistEvent);
  }

  const add_custom_link = event => {
    event.preventDefault();

    if (!website_form_data.url?.trim()) {
      set_website_form_data({
        ...website_form_data,
        error_message: "Required field!",
      });
      return;
    }

    let matched_url = url_validation(website_form_data.url);
    if (matched_url) {
      if (matched_url.indexOf('www.') === 0) {
        matched_url = matched_url.slice(4);
      }
      if (blocked_links.includes(matched_url)) {
        set_website_form_data({
          url: '',
          error_message: `Url already exists.`,
        });
      } else if (matched_url.includes('twella')) {
        set_website_form_data({
          url: '',
          error_message: 'Please do not block us.',
        });
      } else {
        const new_blocked_links = [...blocked_links, matched_url.toLowerCase()];
        update_blocked_links(new_blocked_links);
        fire_blocklist_change_event(matched_url.toLowerCase());
        set_website_form_data({
          url: '',
          error_message: '',
        });
      }
    } else {
      set_website_form_data({
        ...website_form_data,
        error_message: "Invalid url.",
      });

    } try {
      const eventProperties = {
        'User ID': user.extid,
        'Added Link': matched_url.toLowerCase(),
      };

      Mixpanel.track('Site added to My Block List', eventProperties);
    } catch (error) {
      console.error('Error tracking "Site added to My Block List" event in Mixpanel:', error);
    }

    setTimeout(() => {
      set_website_form_data({
        url: '',
        error_message: '',
      });
    }, 5000);
  };

  const remove_link_from_list = link => event => {
    event.preventDefault();

    const index = blocked_links.findIndex(_link => _link === link);

    if (index >= 0) {
      const removedLink = blocked_links[index];
      const new_blocked_links = blocked_links
        .slice(0, index)
        .concat(blocked_links.slice(index + 1));
      update_blocked_links(new_blocked_links);
      set_blocked_links(new_blocked_links);
      fire_blocklist_remove_event(link);

      try {
        const eventProperties = {
          'User ID': user.extid,
          'Removed Link': removedLink.toLowerCase(),
        };
        Mixpanel.track('Site Removed to My Block List', eventProperties);
      } catch (error) {
        console.error('Error tracking "Site Removed to My Block List" event in Mixpanel:', error);
      }
    }
  };

  const add_common_website = link => event => {
    event.preventDefault();

    const new_blocked_links = [...blocked_links, link];
    update_blocked_links(new_blocked_links);
    fire_blocklist_change_event(link);

    try {
      const eventProperties = {
        'User ID': user.extid,
        'Added Common Website': link.toLowerCase(),
      };

      Mixpanel.track('Site added to My Block List', eventProperties);
    } catch (error) {
      console.error('Error tracking "Site added to my block list" event in Mixpanel:', error);
    }
  };


  useEffect(() => {
    const emotionParam = new URLSearchParams(location.search).get('emotion');

    if (emotionParam && !isOpen) {
      setIsOpen(true);
    }

  }, [isOpen, location.search]);


  const closeModal = () => {
    setIsOpen(false);
  };


  useEffect(() => {
    const cookies = new Cookies();

    const updateCookieList = () => {
      const allCookies = cookies.getAll();

      const shouldReload = Object.keys(allCookies).every(key => key !== 'jwt')

      if (shouldReload) window.location.reload();

    };

    updateCookieList();

    const interval = setInterval(() => {
      updateCookieList();
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const sessionStartTimestamp = useRef(Date.now());
  const currentPageRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    const newUser = {
      id: user.extid,
      age: user.age,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      email: user.email
    };

    Mixpanel.identify(user.extid);
    Mixpanel.people.set(newUser);

    try {
      Mixpanel.track('User enter the platform', newUser);
    } catch (error) {
      console.error('Error tracking "User enter the platform" event in Mixpanel:', error);
    }

    const handlePageView = () => {
      const pagePath = history.location.pathname;
      if (currentPageRef.current !== null) {
        const pageDuration = Math.floor((Date.now() - currentPageRef.current) / 1000);
        Mixpanel.track('Viewed Page', { 'Page Duration': pageDuration, 'Page Path': pagePath });
      }
      currentPageRef.current = Date.now();
    };

    const unlisten = history.listen(handlePageView);

    handlePageView();

    return () => {
      unlisten();
      const sessionDuration = Math.floor((Date.now() - sessionStartTimestamp.current) / 1000);
      Mixpanel.track('Left the platform', { 'Session Duration': sessionDuration });
    };
  }, [user.age, user.email, user.extid, user.first_name, user.last_name, user.phone, history]);

  const [flatUser, setFlatUser] = useState();

  const [userTestPlan, setUserTestPlan] = useState();
  const serverURL = process.env.REACT_APP_SERVER_URL;
  const { user_extid, token } = get_token_and_extid()
  const [loading, setLoading] = useState(true);

  const getFlatUsers = useCallback(async () => {
    setLoading(true);
    try {
      const [subscriptionsResponse, userResponse] = await Promise.all([
        fetch(`${serverURL}/api/subscriptions/${user_extid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        fetch(`${serverURL}/api/user/${user_extid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      ]);

      if (subscriptionsResponse.ok) {
        const subscriptionsText = await subscriptionsResponse.text();
        const subscriptionsData = subscriptionsText ? JSON.parse(subscriptionsText) : null;
        setFlatUser(subscriptionsData);
      } else {
        console.warn("Não foi possível obter os dados de inscrições:", subscriptionsResponse.statusText);
      }

      if (userResponse.ok) {
        const userText = await userResponse.text();
        const userData = userText ? JSON.parse(userText) : null;
        setUserTestPlan(userData);
      } else {
        console.warn("Não foi possível obter os dados do usuário:", userResponse.statusText);
      }

    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);
    }
  }, [serverURL, token, user_extid]);

  const cancelSubscription = async () => {
    try {
      const response = await fetch(`${serverURL}/api/subscriptions/${user_extid}/cancel`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        await getFlatUsers();
        console.log("Plano cancelado com sucesso!");
      } else {
        console.error("Erro ao cancelar o plano:", response.statusText);
      }
    } catch (error) {
      console.error("Erro ao cancelar o plano:", error);
    }
  };

  const formatRenewDate = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const formatCreationDate = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    if (userTestPlan?.user_profile?.user?.trial_active === true) {
      setOpenFreeTrial(true);
    }
  }, [userTestPlan]);

  const [isOpenPayment, setOpenPayment] = useState(false);

  const [isOpenFreeTrial, setOpenFreeTrial] = useState(false)

  const openPaymentModal = () => {
    setOpenPayment(true);
  };

  const closeModalFreeTrial = () => {
    setOpenFreeTrial(false);
  };
  const closeModalPayment = async () => {
    setOpenPayment(false);
    await getFlatUsers();
  };


  useEffect(() => {
    getFlatUsers();
  }, [getFlatUsers]);


  useEffect(() => {
    if (location.search === '?=upgrade') {
      setOpenPayment(true);
      history.replace({
        pathname: location.pathname,
        search: ''
      });
    }
  }, [location, history]);


  return (
    <>
      <ToastContainer position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Fragment>
        {to_signin && <Redirect to="/signin" />}
        <Header signout_btn />
        <div className="center top">

          <Modal isOpen={isOpen} onClose={closeModal} >
            <Chat onClose={closeModal} />
          </Modal>

          {isOpenPayment && (
            <ModalModifyPayment isOpen={isOpenPayment} onClose={closeModalPayment}>
              <Payment onClose={closeModalPayment} />
            </ModalModifyPayment>
          )}

          <ModalModifyPayment isOpen={isOpenFreeTrial} onClose={closeModalFreeTrial}>
            <FreeTrial onClose={closeModalFreeTrial} openPaymentModal={openPaymentModal} />
          </ModalModifyPayment>

          <div className="container-twellai-extension">
            <div className='container-get-extension'>
              <div>
                <h2>
                  Don’t have the Extension yet?
                </h2>
                <h1>
                  Download it Now
                </h1>
              </div>

              <div>
                <a
                  href="https://chrome.google.com/webstore/detail/twella/lblidfjkplakdhnmbghmeomhbkakbcko"
                  className="button badge w-button"
                >
                </a>
                <a
                  href="https://apps.apple.com/us/app/twella/id6443976993"
                  className="button badge extension-apple"
                >
                </a>
              </div>
            </div>



            {flatUser === null &&
              userTestPlan?.user_profile?.user?.trial_active === false && (
                <>
                  <button className='container-twellaai-update' onClick={() => {
                    // setIsOpen(true);
                    openPaymentModal(true)
                  }}>
                    <div>
                      <span>Twella AI Mental Health Guide</span>
                    </div>

                    <div>
                      <button className='button-update' onClick={() => {

                      }}>Upgrade</button>
                    </div>
                  </button>
                </>
              )}


            {(userTestPlan?.user_profile?.user?.trial_active === true || flatUser) && (
              <>
                <button className='container-twellaai' onClick={() => {
                  setIsOpen(true);
                  try {
                    const chatOpenedEvent = {
                      'User ID': user.extid,
                      'Age': user.age,
                      'First Name': user.first_name,
                      'Last Name': user.last_name,
                      'Phone': user.phone,
                      'Email': user.email
                    };

                    Mixpanel.track('Opened the chat', chatOpenedEvent);
                  } catch (error) {
                    console.error('Erro ao rastrear o evento "Chat Opened" no Mixpanel:', error);
                  }
                }}>
                  <div>
                    <span>Twella AI Mental Health Guide</span>
                  </div>

                  <div>
                    <img src={webEmojis} alt="Webs + Emojis" />
                    <img src={websPurple} alt="Webs" />
                  </div>
                </button>
              </>
            )}

          </div>

          <div className="main-dashboard">
            <div className="dashboard-left">
              <div className="dashboad-item">
                <h2 className="heading">
                  Profile
                </h2>
                <div className="largedivider" />
                <div className="form w-form">
                  <form className="access-form">
                    <div className="contact-form-grid">
                      <FormControl label="First Name"
                        value={profile_form_data.first_name}
                        onChange={handle_profile_form_change('first_name')}
                      />

                      <FormControl label="Last name"
                        value={profile_form_data.last_name}
                        onChange={handle_profile_form_change('last_name')}
                      />

                      <FormControl label="Phone Number"
                        value={profile_form_data.phone}
                        onChange={handle_profile_form_change('phone')}
                      />
                      <FormControl label="Email"
                        value={profile_form_data.email}
                        onChange={handle_profile_form_change('email')}
                      />
                      <FormControl label="Age"
                        value={profile_form_data.age}
                        onChange={handle_profile_form_change('age')}
                      />
                    </div>
                    <input
                      type="submit"
                      value="Update Profile"
                      className="button large w-button"
                      onClick={submit_profile_form}
                    />
                    {profile_form_data.error_message && (
                      <small className="error-message">{profile_form_data.error_message}</small>
                    )}
                    {profile_form_data.success_message && (
                      <small className="success-message">{profile_form_data.success_message}</small>
                    )}
                  </form>
                </div>
              </div>
              <div className="dashboad-item">
                <h2 className="heading">
                  Reset Password
                </h2>
                <div className="largedivider" />
                <div className="form w-form">
                  <form className="access-form">
                    <div className="contact-form-grid">
                      <FormControl label="New Password"
                        value={profile_form_data.password}
                        onChange={handle_profile_form_change('password')}

                      />
                      <FormControl label="Confirm Password"
                        value={profile_form_data.confirm_password}
                        onChange={handle_profile_form_change('confirm_password')}

                      />
                    </div>
                    <input
                      type="submit"
                      value="Reset Password"
                      className="button large w-button"
                      onClick={submit_password_change}
                    />
                    {password_data.error_message && (
                      <small className="error-message">{password_data.error_message}</small>
                    )}
                    {password_data.success_message && (
                      <small className="success-message">{password_data.success_message}</small>
                    )}
                  </form>
                </div>
              </div>


              {flatUser && (
                <>
                  <div className='dashboad-item'>
                    <h2 className="heading">
                      Subscription
                    </h2>

                    <div className="largedivider" />

                    <div className='container-subscription'>
                      <div>
                        <h1>Twella</h1>
                        <b>$ 4.99<span>/month</span></b>
                      </div>
                      <div className="largedivider" />
                      <div>
                        <span>Renews on:</span>
                        <b>{formatRenewDate(flatUser?.renews_at)}</b>
                      </div>
                      <div>
                        <span>Started on:</span>
                        <b>{formatCreationDate(flatUser?.created_at)}</b>
                      </div>

                      <button onClick={cancelSubscription}>Cancel Subscription </button>
                    </div>
                  </div>
                </>
              )}



              {userTestPlan?.user_profile?.user?.trial_active === true && (
                <>
                  <div className='dashboad-item'>
                    <h2 className="heading">
                      Subscription
                    </h2>

                    <div className="largedivider" />

                    <div className='container-subscription'>
                      <div>
                        <h1>Twella</h1>
                        <b>Free Trial</b>
                      </div>
                      <div className="largedivider" />

                      {userTestPlan?.user_profile?.user?.trial_active === false && (
                        <button onClick={cancelSubscription}>Cancel Subscription </button>
                      )}

                    </div>
                  </div>
                </>
              )}

            </div>
            <div className="dashboard-right">
              <div className="dashboad-item">
                <h2 className="heading">
                  My Session History
                </h2>
                <div className="dashboard__visit-history">
                  {
                    visit_history && visit_history.map((history, index) => (
                      <div key={index} className="session-wrapper dashboard__session-wrapper">
                        <div className="day-wrapper dashboard__day-wrapper">
                          <h2 className="week-day dashboard__week-day">
                            {history.day}
                          </h2>
                          <div className="paragraph-3">
                            {history.date}
                          </div>
                        </div>
                        {
                          history.history.map((entry, e_index) => (
                            <div key={e_index} className="wesite-wrapper dashboard__wesite-wrapper">
                              <h3 className="website dashboard__website">
                                {entry.url}
                              </h3>
                              <div className="paragraph-3 dashboard__action">
                                {entry.action}{entry.did_exercise ? ' (did exercise)' : ''}
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="dashboad-item">
                <h2 className="heading dashboard__blocklist-header">
                  My Block List
                </h2>
                <div className="largedivider" />
                <ol className="list">
                  {
                    blocked_links && blocked_links.map((link, index) => (
                      <li key={index} className="item">
                        <div className="website-wrapper">
                          <h3 className="website">
                            {link}
                          </h3>
                          <button
                            type="button"
                            className="button stroke list-mobile w-button"
                            onClick={remove_link_from_list(link)}
                          >
                            Remove
                          </button>
                        </div>
                      </li>
                    ))
                  }
                </ol>
                <div className="largedivider" />
                <div className="form w-form">
                  <form className="add-website-form">
                    <div className="contact-form-grid side">
                      <input
                        type="text"
                        className="text-field full side w-input"
                        maxLength="256"
                        name="website"
                        placeholder="Add website e.g. asos.com, nike.com"
                        onChange={handle_website_form_change}
                        value={website_form_data.url}
                      />
                    </div>
                    <input
                      type="submit"
                      value="Add website"
                      className="button w-button"
                      onClick={add_custom_link}
                    />
                  </form>

                  {website_form_data.error_message && (
                    <div>
                      <span className="container-error-message-add-website">{website_form_data.error_message}</span>
                    </div>
                  )}
                </div>
                <div className="common-website-wrapper">
                  <div className="paragraph-3">
                    Common websites:
                  </div>
                  <div className="common-websites">
                    {
                      DEFAULT_BLOCKED_LINKS && DEFAULT_BLOCKED_LINKS
                        .filter(link => !blocked_links.includes(link))
                        .map((link, index) => (
                          <button
                            type="button"
                            key={index}
                            onClick={add_common_website(link)}
                            className="common-website-item w-inline-block common-webiste-button"
                          >
                            <img
                              src="images/add-icon.svg"
                              alt="add icon"
                              width="20" height="20"
                              className="add-icon"
                            />
                            <h3 className="website">
                              {link}
                            </h3>
                          </button>
                        ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    </>
  )
}

export default Dashboard
