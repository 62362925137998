import styled, { keyframes, css } from 'styled-components';


const jumpAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 16px;

  height: 100%;

  margin: 11px;
`;

export const Image = styled.img`
  width: 24px;
  height: 24px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  background-color: white;
  border: 1px solid black;
  border-radius: 100%;
  padding: 2px;

  ${(props) =>
    props.visible &&
    css`
      animation: ${jumpAnimation} 1s ease-in-out infinite;
    `};
    
    `;
