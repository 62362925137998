import React, { useEffect, useState } from "react";

import logoBad from "../../assets/images/bad.svg";
import logoBlah from "../../assets/images/blash.svg";
import logoContent from "../../assets/images/content.svg";
import logoHappy from "../../assets/images/happy.svg";
import logoSad from "../../assets/images/sad.svg";
import { Image, LoaderContainer } from "./styled";

const images = [logoBad, logoBlah, logoSad, logoContent, logoHappy];

const Loader = () => {
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % 5);
        }, 650);

        return () => clearInterval(interval);
    }, []);

    return (
        <LoaderContainer>
            {images.map((image, index) => (
                <Image
                    key={index}
                    src={image}
                    alt={`Image ${index + 1}`}
                    visible={index === currentImage}
                />
            ))}
        </LoaderContainer>
    );
};

export default Loader;
