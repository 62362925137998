import styled from "styled-components";

export const InputContainer = styled.div`
  position: relative;
`;

export const InputField = styled.input`
  height: 48px;

  width: 100%;
  padding: 1rem 0rem 0rem 0.5rem;
  border: none;
  border-radius: 4px;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 0 0 1px transparent;
  background-color: #f2f4f7;
  outline: 0;



  &:focus,
  &:focus-within {
    /* box-shadow: 0 0 0 2px dodgerblue; */

  }
`;

export const Label = styled.label`
  position: absolute;

  top: ${(props) => (props.isFocused || props.hasContent ? '1rem' : '50%')}; 
  transform: translateY(-50%);
  font-size: ${(props) => (props.isFocused || props.hasContent ? '10px' : '16px')}; 
  color: ${(props) => (props.isFocused || props.hasContent ? '#9B9B9B' : '#000000')}; 
  font-weight: ${(props) => (props.isFocused || props.hasContent ? '600' : '600')}; 

  transition: all 0.3s ease;
  pointer-events: none;
  background: none;
  padding: 0 0.5rem;
  z-index: 1;
  line-height: 24px;
  margin: 0;

`;





