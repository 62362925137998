import React, { useState } from "react";
import { ContainerFreeTrial, List, ListItem, ContainerButtons } from "./styled";
import Payment from "../Payment";
import { ModalModifyPayment } from "../Payment/styled";

const FreeTrial = ({ onClose, openPaymentModal }) => {
  const [isOpenFreeTrial, setOpenFreeTrial] = useState(false);

  const handleUpgradeClick = () => {
    onClose();
    openPaymentModal();
  };

  const closeModalFreeTrial = () => {
    setOpenFreeTrial(false);
  };

  return (
    <>
      <ModalModifyPayment
        isOpen={isOpenFreeTrial}
        onClose={closeModalFreeTrial}
      >
        <Payment onClose={closeModalFreeTrial} />
      </ModalModifyPayment>

      <ContainerFreeTrial>
        <div>
          <h1>
            Welcome<span>!</span>
          </h1>

          <hr />

          <p>
            Enjoy a 3 day <br />
            <span>Free Trial</span>
          </p>
        </div>

        <div>
          <List>
            <p>Whats Included:</p>
            <ListItem>Unlimited AI chat functionality</ListItem>
            <ListItem>AI chat history access</ListItem>
            <ListItem>AI mood selection</ListItem>
            <ListItem>Cancel anytime</ListItem>
          </List>
        </div>

        <ContainerButtons>
          <button onClick={onClose}>Continue with trial</button>

          <button onClick={handleUpgradeClick}>Upgrade</button>
        </ContainerButtons>
      </ContainerFreeTrial>
    </>
  );
};

export default FreeTrial;
