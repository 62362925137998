import styled, { css, keyframes } from "styled-components";

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

  > div:nth-child(1){
    display: flex;
    padding: 19px 7px 17px  }

    button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;

    padding: 0;
    margin: 0;
  }

  .question-container {
    display: flex;

    margin: 0 auto;

    max-width: 447px;

    border-bottom: 2px solid #e9e4d1;

    margin-bottom: 14px;


    @media screen and (max-width: 425px), screen and (max-width: 600px) {
      margin: 23px;
  }
    > textarea {
      width: 100%;

      height: 100%;
    }

    > h1 { 
      font-size: 17px;

      font-weight: 400;

      margin: 0;

      margin-bottom: 10px;

    }
  }
`;

export const ContainerAsk = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 19px;

  height: auto;
  margin: 0 auto;

  width: 100%;

  padding: 24px;



  > textarea {
    width: 100%;

    border-radius: 12px;
    border: 1px solid #e9e4d1;

    padding: 14px 16px 14px 16px;

    resize: none;

    &::placeholder {
         color: #A6A39D;
    }

  }

  button {
    border: none;

    background: transparent;

    background-color: black;

    border-radius: 12px;

    padding: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ContainerTextEmojis = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 5px 0px 0px 0;
    width: 100%;
    padding: 0 16px;
    position: relative;

    gap: 20px;

    @media screen and (max-width: 320px) { 
      gap: 18px
    }


   
>     div  { 
  > span { 
    font-size: 15px;
    color: #fff;
    margin: 0;

    line-height: 1.2;
    font-weight: 700;

    @media screen and (max-width: 320px){
      font-size: 13px;
    }
  }
}
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 20px 0px 20px;

  width: 100%;

  padding: 0 16px;

  position: relative;


  @media screen and (max-width: 320px){
      margin: 20px 0 0px 0px;


     padding: 0;
    }


  /* background-color: #0b0f22; */

  > div:nth-child(1) {
    position: absolute;
    background: white; 
    width: 400px;
    height: 14px;
    border-radius: 10px;

    @media screen and (max-width: 425px){
      width: 380px;
    }
    @media screen and (max-width: 375px){
      width: 300px;
    }
    @media screen and (max-width: 320px){
      width: 270px;
    }

}

  > div:not(div:nth-child(1)) {
    width: 57px;
    height: 57px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  > div:nth-child(1n + 2) {
    position: sticky;

    background-color: white;

    border: 1px solid black;
    border-radius: 100%;

    padding: 5px;

  }
`;

export const selectedButtonStyles = css`
  /* background-color: red !important; */
`;

export const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  img {
    width: 37px;
    height: 37px;
    vertical-align: baseline;


  }

  ${(props) => props.selected && selectedButtonStyles}

  ${(props) =>
    !props.selected &&
    css`
      filter: grayscale(100%); 
    `}
`;
export const ButtonStorage = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  img {
    width: 37px;
    height: 37px;
    vertical-align: baseline;

  }

  ${(props) => props.selected && selectedButtonStyles}

  ${(props) =>
    !props.selected &&
    css`
      filter: grayscale(100%); 
    `}
`;

export const ContainerInteraction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 447px;

  padding: 20px 0;

  margin: 0 auto;

  > div:nth-child(1) {
    display: flex;
    gap: 10px;
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: row-reverse;

    gap: 5px;
  }
`;

export const QuestionContainer = styled.div`
  display: flex;

  margin: 0 auto;

  max-width: 447px;

  border-bottom: 2px solid #e9e4d1;

  margin-bottom: 14px;

  @media screen and (max-width: 425px), screen and (max-width: 600px) {
    padding: 0px 20px
  }


  > textarea {
    width: 100%;

    height: 100%;

  }

  > h1 { 
    font-size: 17px;

    font-weight: 400;

    margin: 0;

    margin-bottom: 10px;

  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;

  align-items: center;

  text-align: center;

  background-color: #0b0f22;

  padding: 40px 20px;

  width: 100%;


  margin-bottom: 28px;

  > h1 {
    font-size: 20px;
    color: #ffff;

    letter-spacing: -0.25;

    font-weight: 700;

    line-height: 30px;

    max-width: 457px;

    margin: auto 5px;
  }
`;
export const ContainerHeaderStorage = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;

  align-items: center;

  text-align: center;

  background-color: #0b0f22;

  padding: 40px 0px;

  width: 100%;

  max-width: 447px;
  margin: 0 auto;

  > h1 {
    font-size: 20px;
    color: #ffff;

    letter-spacing: -0.25;

    font-weight: 700;

    line-height: 30px;

    max-width: 457px;

    margin: auto 5px;
  }
`;

export const ContainerOverflow = styled.div`
  overflow: auto;

  height: 100%;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0b0f22;

    height: 300px;
  }
`

export const ContainerChat = styled.div`
  height: 100%;

  margin: 0 auto;

  width: 100%;


  padding: 0px 0px 0px 24px;

  > div:nth-child(1) {
    height: auto;

    /* overflow: auto;

    height: 500px; */

    /* &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0b0f22;
    } */
  }
`;

export const ContainerMessage = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;

  margin: 0 auto 20px;
  max-width: 447px;

  @media screen and (max-width: 425px), screen and (max-width: 600px) {
      padding: 0px 20px;
  }

  > h1 {
    font-size: 17px;
    font-weight: 400;

    background-color: #5CA0B3;

    padding: 12px;

    color: #fff;

    border-radius: 12px 12px 12px 4px;

    margin: 0;
    overflow-wrap: break-word;

    max-width: 100%;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  margin: 10px 5px;
  svg {
    border: none;
    background: transparent;
  }
`;



const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerContainer = styled.div`
  position: relative;

  text-align: center;

  border-bottom: 2px solid #e9e4d1;
`;

export const Logo = styled.img`
max-width: 60%;
height: auto;

  ${({ loading }) =>
    loading &&
    css`
      animation: ${rotate} 2s infinite linear;
    `}
`;