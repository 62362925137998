import { shade } from "polished";
import styled, { css } from "styled-components";

export const ModalContent = styled.div`
  background: white;
  position: fixed;
  width: 80%;
  max-width: 500px;
  height: 98%;
  max-height: 944px;
  right: 1%;
  top: 1%;
  border-radius: 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;

    max-height: 100%;

    top: 0;

    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    margin-top: 20px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    right: 0;
    height: calc(100% - 20px);
    top: 0;
  }
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s;

  ${(props) =>
    props.isVisible &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

export const ModalOverlay = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  margin: 10px 5px;
  svg {
    border: none;
    background: transparent;
  }
`;

