import moment from 'moment'
import { get_token_and_extid } from '../auth/index'


const SERVER_URL = process.env.REACT_APP_SERVER_URL
if (!SERVER_URL) {
  throw new Error('missing REACT_APP_SERVER_URL')
}

const parse_moment_string = (moment_string) => {
  return moment(moment_string, 'MMMM Do, YYYY')
}

const process_user = (user) => {
  if (!user.age) {
    user.age = undefined
  }
  user._id = undefined
  return user
}

const process_visit_history = (visit_history) => {
  return visit_history.map(history => {
    const updated_at = history.updatedAt
    const moment_date = moment(updated_at).local()
    history.day = moment_date.format('dddd')
    history.date = moment_date.format('MMMM Do, YYYY')
    return history
  })
    .reduce((history, entry) => {
      const {
        date,
        day,
        url,
        action,
        did_exercise
      } = entry

      const index = history.findIndex(item => item.date === date)

      if (index >= 0) {
        history[index].history.push({ url, action, did_exercise })
      } else {
        history.push({
          date,
          day,
          history: [{ url, action, did_exercise }]
        })
      }
      return history
    }, [])
    .sort((a, b) => parse_moment_string(b.date) - parse_moment_string(a.date))
}

const get_user_profile = () => {
  const {
    token,
    user_extid
  } = get_token_and_extid()

  if (!token || !user_extid) {
    return new Promise((resolve, reject) => {
      return resolve({
        status: 'error',
        error: 'missing token and/or user_extid'
      })
    })
  }

  return fetch(`${SERVER_URL}/api/user/${user_extid}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => response.json())
    .then(response => {
      const { error, user_profile } = response

      if (error) {
        throw new Error('Invalid credentials.')
      }

      const {
        user,
        visit_history
      } = user_profile

      user_profile.user = process_user(user)
      user_profile.visit_history = process_visit_history(visit_history)

      return user_profile
    })
}

const update_user = (form_data) => {
  const {
    token,
    user_extid
  } = get_token_and_extid()

  if (!token || !user_extid) {
    return new Promise((resolve, reject) => {
      return resolve({
        status: 'error',
        error: 'missing token and/or user_extid'
      })
    })
  }

  return fetch(`${SERVER_URL}/api/user/${user_extid}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(form_data)
  })
    .then(response => response.json())
    .then(response => {
      const { error, user_profile } = response

      if (error) {
        throw new Error(error)

        
      }

      const {
        user,
        visit_history
      } = user_profile

      user_profile.user = process_user(user)
      user_profile.visit_history = process_visit_history(visit_history)

      return user_profile
    })
}

export {
  get_user_profile,
  update_user
}
