const DEFAULT_BLOCKED_LINKS = [
  'amazon.com',
  'ebay.com',
  'walmart.com',
  'etsy.com',
  'target.com',
  'bestbuy.com',
  'macys.com',
  'homedepot.com',
  'wish.com'
]

export default DEFAULT_BLOCKED_LINKS
