import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import PrivateRoute from './auth/PrivateRoute'
import Home from './pages/Home'
import Dashboard from './pages/Dashboard'
import Signin from './pages/Signin'
import Signup from './pages/Signup'
import ResetPassword from './pages/ResetPassword'
import ForgotPassword from './pages/ForgotPassword'

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path='/signin' exact
          component={ Signin }
        />
        <Route
          path='/reset-password' exact
          component={ ResetPassword }
        />
        <Route
          path='/forgot-password' exact
          component={ ForgotPassword }
        />
        <Route
          path='/create-account' exact
          component={ Signup }
        />
        <PrivateRoute
          path='/dashboard' exact
          component={ Dashboard }
        />
        <Route
          path='*'
          component={ Home }
        />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
