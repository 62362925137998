import React from 'react'

const Footer = () => (
  <div className="footer">
    <div className="footer-container">
      <div className="w-layout-grid grid">
        <img
          src="images/twella.svg"
          alt="Twella Logo"
          id="w-node-90fa69a2b1db-69a2b1d8"
          className="image-6"
        />
        <div id="w-node-90caa09f7eba-69a2b1d8" className="footer-center">
          <h4>
            Install Extension
          </h4>


          <div className='container-get-extension' style={{ background: "none" }}>
            <div>

            </div>

            <div style={{ flexWrap: "nowrap" }}>
              <a
                href="https://chrome.google.com/webstore/detail/twella/lblidfjkplakdhnmbghmeomhbkakbcko"
                className="button badge w-button"
              >
              </a>
              <a
                href="https://apps.apple.com/us/app/twella/id6443976993"
                className="button badge extension-apple"
              >
              </a>
            </div>
          </div>

        </div>
        <div id="w-node-90fa69a2b1ee-69a2b1d8" className="footer-right">
          <h4 className="h4">
            Follow us
          </h4>
          <div className="w-layout-grid grid-2">
            <a
              id="w-node-90fa69a2b1f2-69a2b1d8"
              href="https://instagram.com/twellaio?igshid=1ptyone7k62hm"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link-block w-inline-block"
            >
              <img src="images/Facebook.svg" alt="instagram" className="image-7" />
            </a>
            <a
              href="https://www.facebook.com/twellaio/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link-block w-inline-block"
            >
              <img src="images/Linkedin.svg" alt="instagram" className="image-8" />
            </a>
            <a
              href="https://twitter.com/twellaio"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link-block w-inline-block"
            >
              <img src="images/twitter1.png" alt="twitter" className="image-9" />
            </a>
            <a
              href="https://www.linkedin.com/company/twella"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link-block w-inline-block"
            >
              <img src="images/Pinterest.svg" alt="linkedin" className="image-10" />
            </a>
            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link-block w-inline-block"
            >
              <img src="images/tiktok.svg" alt="linkedin" className="image-11" />
            </a>
          </div>
        </div>
        <div id="w-node-bd6e95387fe5-69a2b1d8" className="lower-footer">
          <div className="paragraph-3">
            © {new Date().getFullYear()} Twella. All rights reserved.
          </div>
          <div className="lower-footer-right">
            <a href="https://twella.io/feedback" className="paragraph-3">
              Feedback
            </a>
            <div className="spacer" />
            <a href="https://twella.io/privacy-policy" className="paragraph-3">
              Privacy Policy
            </a>
            <div className="spacer" />
            <a href="https://twella.io/terms-of-service" className="paragraph-3">
              Terms of Service
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
