import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes'
import './index.scss'
import Mixpanel from 'mixpanel-browser';

Mixpanel.init('6a329e367118c8709b515a5f8fc5e8f5');

Mixpanel.track('App Opened');

ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById('root')
)
