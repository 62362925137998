import React, { useState } from "react";
import { get_token_and_extid } from "../../../auth";
import * as Yup from "yup";
import cardImage from "../../../assets/icons/banks.svg";
import {
  ContainerFreeTrial,
  List,
  ListItem,
  CloseButton,
  ContainerForm,
  ErrorMessage,
  ContainerButton,
  CardInputContainer,
  CardInput,
  CardIcon,
  Hr,
  LoadingOverlay,
} from "./styled";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Payment = ({ onClose }) => {
  const { user_extid, token } = get_token_and_extid();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newPlan, setNewPlan] = useState({
    card_number: "",
    expiry: "",
    cvc: "",
    product_id: "prod_PzZCZKgYQbbGeb",
    promo: "",
  });

  const [errorPromoCode, setErrorPromoCode] = useState();

  const [errors, setErrors] = useState({});

  const validationSchema = Yup.object().shape({
    card_number: Yup.string()
      .required("Card number is required")
      .matches(/^\d{16}$/, "Card number must have 16 digits"),
    expiry: Yup.string()
      .required("Expiry date is required")
      .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, "Invalid format (MM/YY)"),
    cvc: Yup.string()
      .required("CVC is required")
      .matches(/^\d{3,4}$/, "CVC must have 3 or 4 digits"),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "promoCode") {
      setNewPlan((prevState) => ({
        ...prevState,
        promo: value,
      }));
    } else {
      const numericValue = value.replace(/\D/g, "");
      const limitedValue = numericValue.slice(
        0,
        name === "card_number" ? 16 : 4
      );

      if (name === "expiry") {
        const valueWithoutSlash = limitedValue.replace(/\//g, "");
        let formattedValue = valueWithoutSlash;
        if (valueWithoutSlash.length > 2) {
          formattedValue =
            valueWithoutSlash.slice(0, 2) + "/" + valueWithoutSlash.slice(2);
        }
        setNewPlan((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      } else {
        setNewPlan((prevState) => ({
          ...prevState,
          [name]: limitedValue,
        }));
      }
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await validationSchema.validate(newPlan, { abortEarly: false });

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/payments/${user_extid}/subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(newPlan),
        }
      );

      const errorData = await response.json();

      setErrorPromoCode(errorData.message);

      if (response.ok) {
        toast.success(`Payment processed successfully!`, {
          autoClose: 4000,
        });

        onClose();
      }

      setIsSubmitting(false);
      setErrors({});
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      if (error instanceof Yup.ValidationError) {
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });

        // toast.error(`Error processing payment: ${error}`, {
        //   autoClose: 2000,
        // });
        setErrors(newErrors);
      } else {
        console.error("Error processing payment:", error.message);
      }
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <ContainerFreeTrial>
        <div>
          <div>
            <h1>Twella AI</h1>
            <CloseButton onClick={onClose}>Cancel</CloseButton>
          </div>

          <div>
            <b>$</b>
            <h1>4.99</h1>
            <b>Per Month</b>
          </div>
        </div>

        <Hr />

        <div>
          <List>
            <ListItem>Unlimited AI chat functionality</ListItem>
            <ListItem>AI chat history access</ListItem>
            <ListItem>AI mood selection</ListItem>
            <ListItem>Cancel anytime</ListItem>
          </List>
        </div>

        <ContainerForm>
          <div>
            <div>
              <label>Promo Code</label>
              <input
                type="text"
                name="promoCode"
                placeholder="XXXXXXX"
                value={newPlan.promo}
                onChange={handleInputChange}
              />

              {errorPromoCode && <ErrorMessage>{errorPromoCode}</ErrorMessage>}
            </div>
          </div>

          <Hr />

          <div>
            <div>
              <label>Card number</label>
              <CardInputContainer>
                <CardInput
                  name="card_number"
                  placeholder="1234 1234 1234 1234"
                  value={newPlan.card_number.replace(/(\d{4})/g, "$1 ").trim()}
                  onChange={handleInputChange}
                />
                <CardIcon src={cardImage} alt="Card icon" />
              </CardInputContainer>
              {errors.card_number && (
                <ErrorMessage>{errors.card_number}</ErrorMessage>
              )}
            </div>
          </div>

          <div>
            <div>
              <label>Expiry</label>
              <input
                type="text"
                name="expiry"
                placeholder="MM/YY"
                value={newPlan.expiry}
                onChange={handleInputChange}
              />
              {errors.expiry && <ErrorMessage>{errors.expiry}</ErrorMessage>}
            </div>

            <div>
              <label>CVC</label>
              <input
                type="text"
                name="cvc"
                placeholder="CVC"
                value={newPlan.cvc}
                onChange={handleInputChange}
              />
              {errors.cvc && <ErrorMessage>{errors.cvc}</ErrorMessage>}
            </div>
          </div>
        </ContainerForm>

        <Hr />

        <ContainerButton>
          <button onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? "Upgrading Account..." : "Sign Up"}
          </button>
        </ContainerButton>
      </ContainerFreeTrial>

      {/* Componente de Loading */}
      {/* {isSubmitting && <LoadingOverlay>Loading...</LoadingOverlay>} */}
    </>
  );
};

export default Payment;
