import { Modal } from "../index";
import styled from "styled-components";

export const ModalModifyPayment = styled(Modal)`
  background: #12141d;
  border: 2px solid #5ca0b3;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 40px;
  border-radius: 20px;

  width: 430px;
  height: auto;
`;

export const ContainerFreeTrial = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    width: 100%;

    margin-bottom: 24px;

    > div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 5px;

      > h1 {
        font-size: 20px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        opacity: 80%;
        margin: 0;
      }

      > button {
        color: red;
      }
    }

    > div:nth-child(2) {
      display: flex;
      align-items: end;
      gap: 5px;

      > b:nth-child(1) {
        font-size: 30px !important;
      
      }
      > h1,
      b {
        font-size: 60px;
        color: rgba(255, 255, 255, 1);
        letter-spacing: -2px;
        font-weight: 900;
        margin: 0;
      }
      > b:nth-child(3) {
        font-size: 16px;
        opacity: 70%;
        letter-spacing: 0;
        color: rgba(255, 255, 255, 1);

        font-weight: 400;
      }
    }
  }

  > div:nth-child(3) {
    display: flex;

    width: 100%;

    margin-bottom: 24px;
    margin-top: 14px;
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  font-weight: 600;
  font-size: 16px;


  color: #fff;

  &::before {
    content: "✔";
    display: flex;
    justify-content: center;
    text-align: center;

    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(134, 231, 184, 1);
    margin-right: 10px;

    color: #fff;
  }
`;

export const List = styled.ul`
  list-style: none;
  padding-left: 0;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  margin: 10px 5px;
  svg {
    border: none;
    background: transparent;
  }
`;

export const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-bottom: 15px;

  > div {
    display: flex;
    gap: 10px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 4px;

      width: 100%;
      > label {
        color: rgba(255, 255, 255, 1);
        font-size: 13px;
        font-weight: 600;
        margin: 0;

        opacity: 50%;
      }

      > input {
        border: 2px solid rgba(224, 224, 224, 1);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
        height: 43px;

        width: 100%;
        border-radius: 6px;
        padding: 10px 12px 10px 12px;

        &::placeholder {
          opacity: 50%;
        }
      }
    }
  }
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 0.8rem;
`;

export const ContainerButton = styled.div`
  width: 100%;

  > button {
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    letter-spacing: 0.1px;
    background: rgba(92, 160, 179, 1);
    width: 100%;
    height: 48px;
    border-radius: 5px;
  }
`;

export const CardInputContainer = styled.div`
  position: relative;
`;

export const CardInput = styled.input`
  border: 2px solid rgba(224, 224, 224, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
  height: 43px;
  width: 100%;
  border-radius: 6px;
  padding: 10px 12px 10px 10px;

  &::placeholder {
    opacity: 50%;
  }
`;

export const CardIcon = styled.img`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 100px;
  height: auto;
`;

export const Hr = styled.hr`
  border: 1px solid rgba(255, 255, 255, 1);
  opacity: 10%;
  width: 100%;

  margin-bottom: 15px;
`;


export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  color: white;
  font-size: 24px;
`;